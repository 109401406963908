import { useAuthStore } from "~/stores/AuthStore";
import { useLoginStore } from "~/stores/LoginStore";
import { useHistoryStore } from "~/stores/HistoryStore";

export default defineNuxtRouteMiddleware(async () => {
  const authStore = useAuthStore();
  const loginStore = useLoginStore();
  const historyStore = useHistoryStore();
  await authStore.checkAuthState();
  if (!authStore.token) {
    return historyStore.to("/");
  }
  if (!loginStore.isAdmin()) {
    return historyStore.to("/");
  }
});
